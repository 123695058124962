let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as nav from './scripts/nav';
nav.init();

import * as swiper from './scripts/swiper';
swiper.init();

import * as headlineFadeIn from './scripts/headline-fade-in';
headlineFadeIn.init();

import * as headlineParallax from './scripts/headline-parallax';
headlineParallax.init();

import * as imageParallax from './scripts/image-parallax';
imageParallax.init();

import * as decorationParallax from './scripts/decoration-parallax';
decorationParallax.init();

import * as contentConnector from './scripts/content-connector';
contentConnector.init();

import * as scrollAnimator from './scripts/scroll-animator';
scrollAnimator.init();

import { init } from "@elements/cookie-permissions/bootstrap-5";
init();

import * as scrollSpy from './scripts/scroll-spy';
scrollSpy.init();

import * as tilt from './scripts/tilt';
tilt.init();


