import {onFind} from "@elements/init-modules-in-scope";
import {add} from "@elements/scroll-animations";

export function init() {
    onFind('.js-content-connector', function (element) {
        add(
            [element],
            calculateAnimationProgress,
            setAnimationProgress
        );
    });
}

function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = 0;

    element.dataset.progress = (1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    ));

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    progress = 1 - Math.pow(1 - progress, 2);
    progress -= .5;
    if (progress < 0) {
        // progress = progress * progress;
        element.style.transform = `scaleY(${1 - Math.abs(progress) * 2})`;
        element.style.transformOrigin = 'top center';
    } else {
        // progress = progress * progress;
        element.style.transform = `scaleY(${1 - (progress * 2)})`;
        element.style.transformOrigin = 'bottom center';
    }
}