import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-scroll-animator', function (ScrollElement) {
        window.addEventListener('scroll', function(e ) {
            if(window.scrollY >= 200){
               ScrollElement.classList.add('is-hidden');
            }

            if(window.scrollY < 200){
                ScrollElement.classList.remove('is-hidden');
            }
        })
    })
}
