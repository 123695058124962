import {findAll} from "@elements/dom-utils";
import VanillaTilt from "vanilla-tilt"

export function init (){
    if (!matchMedia('(max-width: 767px)').matches) {
        findAll('.js-tilt').map(function (element) {
            const max = element.dataset.tiltMax || 7;

            VanillaTilt.init(element, {
                reverse: true,
                max: max
            });
        })
    }
}