import Swiper, {Navigation, Pagination} from 'swiper';
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, findIn} from "@elements/dom-utils";

export function init() {
    Swiper.use([Navigation, Pagination]);

    onFind('.js-swiper', function (swiperElement) {

        const id = swiperElement.dataset.swiperId
        let yearNumbers = JSON.parse(swiperElement.dataset.swiperYears);
        let nextElement = findIn('.swiper-button-following', swiperElement);
        let prevElement = findIn('.swiper-button-previous', swiperElement);
        let scrollbar = findIn('.swiper-scrollbar', swiperElement);
        let pagination = findIn('.swiper-pagination', swiperElement);
        let paginationScrollArea = findIn('.swiper-pagination__container', swiperElement);
        let swiper = new Swiper(findIn('.js-swiper__swiper', swiperElement), {
            direction: 'horizontal',
            loop: true,

            pagination: {
                el: pagination,
                clickable: true,
                renderBullet: function (index, className) {
                    return `
                        <div class=${className}>
                            <div class="swiper-pagination-bullet-item">
                                <div class='swiper-pagination-bullet-decoration'></div>
                                <div class="swiper-pagination-bullet__year">${yearNumbers[index]}</div>
                            </div>
                        </div>`;
                },
            },

            navigation: {
                nextEl: nextElement,
                prevEl: prevElement,
            },

            scrollbar: {
                el: scrollbar,
            },
        })

        swiper.on('activeIndexChange', function ({realIndex,...rest}) {
            let activePagingElement = pagination.children[realIndex];

            if (activePagingElement.getBoundingClientRect().x + 30 >= window.innerWidth
                || activePagingElement.getBoundingClientRect().x < 0){
                // not in viewport

                paginationScrollArea.scrollTo({
                    left: activePagingElement.offsetLeft - paginationScrollArea.offsetWidth + 50,
                    behavior: 'smooth'
                })
            }
        });
    })
}
