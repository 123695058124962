import {onFind} from "@elements/init-modules-in-scope";
import {add} from "@elements/scroll-animations";

export function init() {
    if(window.matchMedia('(min-width: 768px)').matches) {
        onFind('.js-decoration-parallax', function (element) {
            add(
                [element],
                calculateAnimationProgress,
                setAnimationProgress
            );
        })
    }
}

function calculateAnimationProgress(element) {
    const start = window.innerHeight * 10 / 8;
    const end = -200;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top + element.getBoundingClientRect().height / 2  - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
    element.style.transform = 'translateY(-' + (progress) * 8 + 'rem)';
}