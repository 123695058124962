import {find, findAll, closest, toggleClass, addClass, on} from "@elements/dom-utils";

export function init() {

    let navOverlay = find('.js-nav__overlay');
    let burger = find('.js-nav__burger');
    let navLink = findAll('.js-nav__link');

    console.log(navLink)

    const burgerClickHandler = (evt) => {
        toggleClass('is-collapsed', navOverlay);
        toggleClass('is-collapsed', burger);
    };
    on('click', (evt) => burgerClickHandler(evt), burger);

    navLink.forEach(link => {
        on('click', (evt) => burgerClickHandler(evt), link);
    })
}
