import {ScrollSpy} from 'bootstrap';
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn} from "@elements/dom-utils";

export function init() {
    onFind('.js-scroll-spy', function (element) {
        findAllIn('.js-scroll-spy__item', element)
            .map(item => {
                if (!(item.getAttribute('href').startsWith('http') || item.getAttribute('href').startsWith('//'))) {
                    let t = item.getAttribute('href').split('#')
                    let targetId = t[1] || t[0];

                    item.dataset.bsTarget = `#${targetId}`;
                }
            });

        new ScrollSpy(document.body, {
            target: '.js-scroll-spy'
        })
    });
}
