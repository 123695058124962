import {onFind} from "@elements/init-modules-in-scope";
import {add} from "@elements/scroll-animations";

export function init() {
    onFind('.js-headline-parallax__text', function (element) {
        add(
            [element],
            calculateAnimationProgress,
            setAnimationProgress
        );
    })
}

function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = 0;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top + element.getBoundingClientRect().height / 2  - end) / (start - end),
            1
        ),
        0
    );
}

function setAnimationProgress(element, progress) {
        element.style.transform = 'translateY(' + (progress) * 10 + 'rem)';
}